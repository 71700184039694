<template>
    <el-main>
        <el-button style="margin-bottom: 15px" icon="el-icon-plus" type="primary" size="small"
            @click="$router.push('/extension/activity/addSchedule')">添加赛程</el-button>
        <el-form class="el-form-search" label-width="90px">
            <el-form-item label="赛程名称：">
                <el-input size="small" v-model="searchForm.events_name"></el-input>
            </el-form-item>
            <el-form-item label="是否开启：">
                <el-select size="small" v-model="searchForm.status">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="开启" :value="2"></el-option>
                    <el-option label="关闭" :value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="活动名称：">
                <el-select size="small" v-model="searchForm.activity_id">
                    <el-option v-for="(item, index) in activityList" :key="index" :label="item.activity_name"
                        :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="活动状态：">
                <el-select size="small" v-model="searchForm.events_status">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="未开始" :value="1"></el-option>
                    <el-option label="进行中" :value="2"></el-option>
                    <el-option label="已结束" :value="3"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label=" " label-width="25px">
                <el-button size="small" @click="search" type="primary">搜索</el-button>
                <el-button size="small" @click="searchClear" type="text">清除搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
            <template slot="empty">
                <No />
            </template>
            <el-table-column width="120" align="center">
                <template #header>
                    <div class="edit">
                        <span>排序</span>
                        <i class="el-icon-edit-outline"></i>
                    </div>
                </template>
                <template v-slot="{ row }">
                    <el-input type="number" :min="0" size="small" v-model="row.sort" @change="quickEdit(row, 1)"></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="picture" label="赛程图片" align="center">
                <template v-slot="{ row }">
                    <el-image style="width: 100px; height: 70px" fit="scale-down" :src="row.picture"></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="events_name" label="赛程名称" align="center"></el-table-column>
            <el-table-column label="虚拟参赛人数" align="center">
                <template v-slot="{ row }">
                    <el-input type="number" :min="0" size="small" v-model="row.virtual_apply_user_sum"
                        @change="quickEdit(row, 3)"></el-input>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="events_status" label="活动状态" align="center"></el-table-column> -->
            <el-table-column prop="is_show" label="是否开启" align="center">
                <template v-slot="{ row }">
                    <el-switch v-model="row.status" @change="quickEdit(row, 2)" :active-value="1"
                        :inactive-value="0"></el-switch>
                </template>
            </el-table-column>
            <el-table-column prop="money" label="操作" align="center">
                <template v-slot="{ row }">
                    <el-button @click="$router.push({ path: '/extension/activity/editSchedule', query: { id: row.id } })"
                        size="small" type="text">编辑</el-button>
                    <el-button @click="del(row)" size="small" type="text">删除</el-button>
                    <el-button @click="draw(row)" size="small" type="text">抽签晋级</el-button>
                    <el-button size="small" type="text"
                        @click="$router.push('/extension/activity/scheduleAward?events_id=' + row.id)">赛程颁奖</el-button>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData">
        </Paging>
    </el-main>
</template>

<script>
import _ from 'lodash';
import Paging from '@/components/paging';
export default {
    components: {
        Paging,
    },
    data () {
        return {
            activityList: [],
            searchForm: {
                page: 1,
                rows: 10,
                events_name: '',
                status: '',
                events_status: '',
                start_time: '',
                end_time: '',
                activity_id: 0,
            },
            list: [],
            total_number: 0,
        };
    },
    created () {
        if (this.$route.query.activity_id) {
            this.searchForm.activity_id = Number(this.$route.query.activity_id);
        }
        this.getAllActivityList();
        this.getList();
    },
    methods: {
        search () {
            this.searchForm.page = 1;
            this.searchForm.rows = 10;
            this.getList();
        },
        searchClear () {
            this.searchForm = {
                page: 1,
                rows: 10,
                events_name: '',
                status: '',
                events_status: '',
                start_time: '',
                end_time: '',
                activity_id: 0,
            };
            this.getList();
        },
        updateData (val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
            } else {
                this.searchForm.page = val;
            }
            this.getList();
        },
        draw (row) {
            this.$axios
                .post(this.$api.sports.drawLotsInfo, {
                    events_id: row.id,
                })
                .then(res => {
                    if (res.code == 0) {
                        if (res.result.team.length >= 5) {
                            this.$router.push({
                                path: '/extension/activity/draw',
                                query: {
                                    id: row.id,
                                },
                            });
                        } else {
                            this.$message.warning('至少需要5只战队');
                        }
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        quickEdit (row, type) {
            row.sort = Number(row.sort);
            row.virtual_apply_user_sum = Number(row.virtual_apply_user_sum);
            let data = {
                id: row.id,
            };
            if (type == 1) data.sort = row.sort;
            if (type == 2) data.status = row.status;
            if (type == 3) data.virtual_apply_user_sum = row.virtual_apply_user_sum;
            this.$axios.post(this.$api.sports.quickEdit2, data).then(res => {
                if (res.code == 0) {
                    console.log(res);
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        del (row) {
            this.$confirm('请确认是否删除？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios
                    .post(this.$api.sports.delSchedule, {
                        id: row.id,
                    })
                    .then(res => {
                        if (res.code == 0) {
                            this.$message.success('删除成功');
                            this.getList();
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
            });
        },
        getList () {
            let searchForm = _.cloneDeep(this.searchForm);
            this.$axios.post(this.$api.sports.scheduleList, searchForm).then(res => {
                if (res.code == 0) {
                    let list = res.result.list;
                    this.list = list;
                    this.total_number = res.result.total_number;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        // 获取所有的活动列表
        getAllActivityList () {
            this.$axios.post(this.$api.sports.activityLists).then(res => {
                if (res.code === 0) {
                    this.activityList = res.result;
                    this.activityList.unshift({ activity_name: '全部', id: 0 });
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;

    .el-table {
        /deep/ .el-input__inner {
            border-color: #fff;
        }

        /deep/ .el-input__inner:focus {
            border-color: #409eff;
        }
    }
}</style>
